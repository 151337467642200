<template>
  <div class="landing">
    <div>
      <h1 data-aos="fade-up">Trusted &<br />Experienced</h1>
    </div>
    <img src="@/assets/images/landing.jpg" alt="Mobile Developer" srcset="" />
    <h1 data-aos="fade-up" class="developer-text">Developer</h1>
    <hr />
  </div>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<style lang="scss">
@import "@/styles/home.scss";
</style>
