<template>
  <div id="about" class="about d-flex flex-column flex-xl-row justify-content-between">
    <div>
      <h6 class="d-flex">
        (01)
        <div class="mx-4"></div>
        ABOUT
      </h6>
      <img
        class="about1"
        data-aos="fade-up"
        src="@/assets/images/about1.png"
        alt="Working"
      />
    </div>
    <div class="text-start">
      <h2 data-aos="fade-up">
        SUPPORTING<br />
        BUSINESSES<br />
        LIKE YOURS,
      </h2>
      <h4>
        by empowering companies,<br />
        with thoughtful advice<br />
        and hassle-free development
      </h4>

      <img
        class="about2"
        data-aos="fade-up"
        data-aos-anchor-placement="bottom-bottom"
        src="@/assets/images/about2.png"
        alt="Working"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style></style>
