<template>
  <div id="partners" class="partners">
    <h6 class="d-flex">
      (03)
      <div class="mx-4"></div>
      PARTNERS
    </h6>
    <div class="row img-section">
      <div class="col-12 col-md-7 d-flex">
        <div
          class="partner1"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <img
            src="@/assets/images/partner1.jpg"
            alt="William Brown"
            class="img-fluid"
          />
          <div
            class="d-flex justify-content-between flex-column flex-md-row align-items-center mt-4"
          >
            <h5>WILLIAM BROWN</h5>
            <h6>RM + PARTNER</h6>
            <span></span>
          </div>
        </div>
        <div
          class="partner2"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <img
            src="@/assets/images/partner2.jpg"
            alt="David Johnson"
            class="img-fluid"
          />
          <div
            class="d-flex justify-content-between flex-column flex-md-row align-items-center mt-4"
          >
            <h5>DAVID JOHNSON</h5>
            <h6>PD + PARTNER</h6>
            <span></span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 d-flex justify-content-center">
        <h4
          class="text-start w-100 w-md-75"
          data-aos="fade-left"
          data-aos-anchor-placement="bottom-bottom"
        >
          I have wealth of experience working with corporate and personal
          clients. Many of those clients have been working with us for more than
          two years.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
};
</script>

<style lang="scss">
@import "@/styles/home.scss";
</style>
