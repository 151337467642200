<template>
  <div id="horizontal-scroll-text" class="horizontal-scroll-text">
    <h2 class="display-4 text1">DEVELOPING SINCE `016 DEVELOPING SINCE `016 DEVELOPING SINCE `016</h2>
    <h2 class="display-4 text2">DEVELOPING SINCE `016 DEVELOPING SINCE `016 DEVELOPING SINCE `016</h2>
  </div>
</template>

<script>
export default {
  name: "ScrollText",
};
</script>

<style></style>
